require('../sass/copyright.scss');

let config = {
	logo: {
		white: 'https://vegas-dev.com/assets/_frontend/images/logo/logo.svg',
		dark: 'https://vegas-dev.com/assets/_frontend/images/logo/logo-white.svg'
	},
	titles: {
		dev: {ru: 'Разработка и продвижение', en: 'Development and promotion'},
	}
};

let copyrightContainer = document.getElementById('vg-copyright');
let theme = copyrightContainer.dataset.theme || 'white';
let lang = copyrightContainer.dataset.lang || 'ru';

let copyrightWrapper = document.createElement('div');
copyrightWrapper.classList.add('vg-copyright-wrapper');

let copyrightText = document.createElement('span');
copyrightText.classList.add('vg-copyright-text');
copyrightText.innerText = (config.titles.dev[lang] || '') + ' - ';

let copyrightLink = document.createElement('a');
copyrightLink.classList.add('vg-copyright-link');
copyrightLink.setAttribute('href', 'https://vegas-dev.com/');
copyrightLink.setAttribute('target', '_blank');
copyrightLink.setAttribute('rel', 'nofollow');

let copyrightImage = document.createElement('img');
copyrightImage.classList.add('vg-copyright-image');
copyrightImage.setAttribute('src', config.logo[theme] || '');
copyrightImage.setAttribute('alt', 'Студия Вегас');

let copyrightYear = document.createElement('span');
copyrightYear.classList.add('vg-copyright-year');

let year = copyrightContainer.dataset.year || 2023;
copyrightYear.innerText = year;

copyrightLink.append(copyrightImage);
copyrightLink.append(copyrightYear);

copyrightWrapper.append(copyrightText);
copyrightWrapper.append(copyrightLink);

copyrightContainer.append(copyrightWrapper);